@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #162b4d;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #162b4d;
}
 

.pt-9 {
  padding-top: 9rem !important ;
}
.bg-primary {
  background-color: #172a4d!important;
}
.bg-secundary {
  background-color: #f4355b!important;
}
.restaurant-detailed-header-right {
  margin-bottom: 10px;
}

.radius-primary{
  border-radius: 10px !important;
  overflow: hidden;
}
.disabled .bg-primary{
  background-color: #172a4c73 !important
}
.disabled .text-primary{
     color: #f4355b59 !important;
 }
.disabled .bg-primary{
  background-color: #e3e3e3 !important
}
.disabled .text-secondary{
  color: #e3e3e3 !important
}
.disabled .border-primary {
  border-color: #172a4d21!important;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #f4355b3b;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(244 53 91 / 31%);
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #314461;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}
.homepage-search-block {
  background-color: #fefffe;
}
.price-art{
  padding: 0!important;
  text-align: center;
  margin-top: 30px;
  font-size: medium;
  font-weight: 700;
  color: #f4395f;
  float:right;
}
.price-artH{
  padding: 0!important;
  text-align: center;
  margin-top: 10px;
  font-size: medium;
  font-weight: 700;
  color: #f4395f;
  float:right;
}
.border-primary {
  border-color: #172a4d!important;
}
.myhead {
  font-weight: 900 !important;
  font-size: 3.1rem !important;
}
.navbar-brand img {
  max-width: 140px;
}
.navbar-light .navbar-nav .nav-link,.navleft {
  color: rgb(21 43 76);
  border-radius: 10px;
  border: 2px solid;
  padding: 10px !important;
  overflow: hidden;
}
.navleft{
  color: #162b4d !important;
  margin-left: 5px;
  padding: 10px !important;
}
.osahan-nav .nav-link {
  font-weight: 800;
  padding: 0px 0px !important;
  margin: 0 0 0 0px;
}
.bg-image {
  background-size: cover;
  background-position: center;
  background-image: url("./../img/gallery/bg.jpg");
}
.spinner {
  width: 100%;
  height: 100%;
   
    
}
.nav-link i {
  width: 28px;
  height: 28px;
  background: #162b4d;
  display: inline-block;
  text-align: center;
  line-height: 29px;
  font-size: 15px;
  border-radius: 50px;
  margin: 0 7px 0 0px;
  color: #fff;
}
.spinner > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dishesList{
  top: 60px;
  z-index: 8;
    background-color: #fff;
    padding: 20px;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0 3px 5px rgb(57 63 72 / 10%);
}
.dishesList button{
  font-size: 0.8rem;
}
.osahan-nav {
  background: #fff none repeat scroll 0 0 !important;
  padding: 16px;
  z-index: 9;
  box-shadow: 0 3px 5px rgb(57 63 72 / 10%);
  padding-left: 10px;
  padding-right: 10px;
}
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgb(245 207 215);
}
.btn {
  border-radius: 15px;
}
.loading {
  width: 90%;
  background: #162b4d;
  padding: 20px;
  text-align: center;
  margin: 5%;
  color: white;
}
.loading h1 {
  color: white;
  font-weight: 800;
}
.firstL,
.cd-words-wrapper {
  color: #f4355b;
}
.sticky{
  position: sticky;
}
.sticky-top{
  top: 60px;
  background-color: #fff;
  
}
.stickedtop{
  top: 60px;
  background-color: #fff;
  padding: 20px;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0 3px 5px rgb(57 63 72 / 10%);
}
.bg-gray {
   background-color: #f3f6f9;
}
.text-secondary {
  color: #162b4d !important;
}
.homepage-search-title h5 {
  font-weight: 600 !important;
}
.register h2, .register p, .register a{
  color: #fff !important;
  font-weight: 900 !important;
 
}
.register{
  background-color: #00000059;
  border-radius: 20px;
  padding: 10px;

}
.register a{
  border-color: #f3f6f9 !important;
}
.register h2{
  font-size: 25px !important;
  margin-top: 25px;
}
.register p{
  font-size: 17px !important;
}
.section-header h2 {
  font-size: 1.5em;
  font-weight: 600;
}
.section-header {
  margin-bottom: 10px;
}

.owl-carousel {
  display: none;
  width: 99% !important;
  z-index: 1;
}
.pb-4, .py-4 {
  padding-bottom: 2rem!important;
}

.catimage {
  max-height: 80px !important;
}
.h-category h6 {
  text-align: center;
}
.dropdown, .dropleft, .dropright, .dropup {
  position: relative;
  margin-left: 5px;
  padding-right: 5px !important;
  border-radius: 10px;
  border: 2px solid;
  font-size: xx-large;
  padding: 2px 5px 2px 5px !important;
}
.langm .dropdown-menu{
   
  min-width: 3rem !important;
  font-size: 1rem;
  left: -8px;
  
   
}
.dropdown-item {
  border-radius: 0 !important;
  padding: 7px 12px;
  color: rgba(0,0,0,.5);
  font-weight: 500;
}
.h-restaurant {
  padding: 20px !important;
}
.dishesList .nav li{
  display: inline-block;
  float: none;
  margin: 0 !important;
  flex: auto;
  padding: 5px;
}
.btn.focus, .btn:focus, .btn:active {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(244 53 91 / 34%);
}
.qrshopname{
  font-size: 3vw;
  text-align: left;
}
.shopdetailsqr {
  color: white;
  position: absolute;
  bottom: 20px;

}
.leftqr{
  left:20px
}
.rightqr{
  right: 20px;
}
.text-left{
  text-align: left !important;
}
.text-right{
  text-align: right !important;
}
.shopdetailsqr h6{

  color: white;
 
}
.shopdetailsqr p{
  color: white;
  font-size: 1.5vh;

}
.shopdetailsqr span{
  color: white;
  font-weight: 800;

}
@media (min-width:769px){
  .imagePageDetails{
    min-height: 430px;
    margin-top: 75px;
  }
  .homepage-ad {
    border: 8px solid;
    border-radius: 10px;
    padding: 1px;
  }
  .homepage-ad::after{
    content: "";
    background-color: #162b4d;
      position: absolute;
      transform: translateX(-50%);
     
      width: 36px;
      height: 36px;
      bottom: -40px;
      left: 50%;
      border-radius: 10px 10px 0 0;
    
  }
  .osahan-account-page {
    padding-top: 7rem !important;
  }
  .lngbtnqr button {
    
    padding: 0px;
    padding-right: 15px;
    margin: 0px;
}
.lngbtnqr button:focus,.lngbtnqr button:active ,.lngbtnqr button.active {
  margin-top: -20px;
  box-shadow: 0 0 0 0 rgb(245 207 215) !important;
}
  .lngqr{
    font-size: 30px;
  }
  .btnqr{
    position:absolute  !important;
    bottom: 15px  !important;
    left: 15px  !important;
    border-radius: 10px  !important;
    background-color: transparent !important;
    font-size: 20px !important;
    padding: 5px !important;
    color: white !important;
    border-color: white !important;
    
  
  }
  .logo-shopqr{
    top:10%;
    position:absolute;
    width: 30% !important;
    z-index: 2;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  
     height: 40% !important;
    position: absolute;
    border-radius: 8px !important;
    bottom: 23%;
    left: 3%;
    object-fit: contain;
    background: white;
    padding: 2px;
  
  }
  .logoqrpage{
    height: 50px;
    width: auto !important;
  }
 
  .qrscan .card{

    overflow: hidden;
    }
    .qrscan .banner-img{
     
      position:initial;
       width: 100%;
        height: auto;
        height: 100%;
        min-height: 210px;
        object-fit: cover;
       
    }
  .restaurant-detailed-header-left {
    text-align: left;
}
  .nodesk{
    display: block;
  }
  .dishesList .nav{
    white-space: nowrap;
    overflow-x: auto;
    display: block;
    flex-wrap: nowrap;
    max-height: 59px;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .dishesList .nav li a{ 

      color: #333333;
      margin: 5px 0;
      padding: 6px 10px;
      border-radius: 29px;
      font-weight: 600;
      font-size: 16px;
      -moz-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
   
  }

  .h-dish{
    padding:10px !important;
  }
  .my-dish img{
    max-height: 120px;
    object-fit: cover;
   width: 100% !important;
  }
  .my-dish h6{
    min-height: 40px;
  }
 .details-shop{
   bottom: 0;
  position: absolute;
  display: block;
  width: 100%;
  padding: 2%;
 }
.navbar-brand {
    
    padding-top: 0.8rem;
   
}
  .shop-header{
    overflow: hidden;
    max-height: 500px;
    position: relative;
  }
  .shop-content{
    position: relative;
     top:10% !important;
    padding:10%;
  }
  .shop-content h3{
    font-weight: 800 !important;
    font-size: 5.5vw  !important;
    margin-bottom: 25px !important;
  }
  .shop-content h4{
    font-weight: 500 !important;
    font-size: 1.5vw !important;
   }
   .shop-content h5{
    font-weight: 500 !important;
    font-size: 2.5vw !important;
    padding-top: 10px;
  }
  .restaurant-detailed-header {
    top: 10%;
    left: 0;
    padding: 65px 0 17px;
    position: absolute;
    right: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.83) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#d4000000', GradientType=0);
}
.restaurant-baner{
  position: relative;
    min-height: 510px;
}

.banner-img{
  position: absolute;
    max-width: 100%;
    height: 100%;
    max-height: 510px;
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d;
    object-fit: cover;
    border-radius: 10px;
}

.logo-shop {
  top: 6%;
  position: absolute;
  width: 10% !important;
  z-index: 2;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  width: 27% !important;
  height: 20% !important;
  position: absolute;
  border-radius: 8px !important;
  border: 2px white solid;
  bottom: 23%;
  right: 3%;
  object-fit: contain;
  background: #162b4d;
  padding: 2px;
}
.feture-logo {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  width: 5rem !important;
  height: 28% !important;
  position: absolute;
  border-radius: 8px !important;
  border: 2px solid white;
  top: 6%;
  right: 3%;
  object-fit: contain;
  background: #162a56;
  padding: 2px;
}
}
.restaurant-detailed-header-left h1{
  font-weight: 700;
  font-size: 2em;
  line-height: 1;
  text-align: left;
  max-height: 3.6em;
  word-break: break-word;
}
.address{
  font-weight: 700;
  font-size: 13px;
  line-height: 1;
  max-height: 3.6em;
}
.ngr img{
  width: 20px !important;
  height: 20px !important;
  
  margin-bottom: 0 !important;
}
.shadow-smmy{
  box-shadow: 0 0.125rem 0.00rem 0 rgba(0,0,0,.075)!important;
}
.profil_image{
  width: 47%;
  max-width: 46px;
  border-radius: 2px !important;
  margin-top: -13px !important;
  margin-left: -16px !important;
  margin-bottom: -10px !important;
}
.modal-footer .btn {
  font-size: 14px;
  padding: 8px 14px;
}
.h-category{
  min-height:54px ;
  display: table;
}
.h-category h6{
  display: table-cell;
   vertical-align: middle;
}

@media (max-width: 768px) {
  .floatingMapButton{
    position:absolute;
    z-index: 2;
    bottom:5% !important;
    left: 0px;
    top: 80% !important;
  }
  .floatingMap{
display: none;
  }
  .imagePageDetails{
    min-height: 280px;
    margin-top: 75px;
  }
  .myfrontslide .my-featured img.banner-img-front{
    height: 200px !important;
  }
  .h-restaurant .shop-name{
    min-height: 40px;
    vertical-align: middle;
    display: table-cell;
  }
  .feture-logo {
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
    width: 4rem !important;
    height: 22% !important;
    position: absolute;
    border-radius: 8px !important;
    border: 2px solid white;
    top: 6%;
    right: 3%;
    object-fit: contain;
    background: #162a56;
    padding: 2px;
}
  .osahan-account-page {
    padding-top: 4rem !important;
}
  .restaurant-detailed-header-left h1 {
    font-weight: 700;
    font-size: 7.5vmin;
    line-height: 1;
    max-height: 3.6em;
    word-break: break-word;
}
  
  .osahan-user-media img {
    width: 140px ;
}
  .lngbtnqr button {
    padding-right: 5px;
    padding: 0px;
    padding-right: 5px;
    margin: 0px;
}
.lngbtnqr button:focus,.lngbtnqr button:active ,.lngbtnqr button.active {
  margin-top: -16px;
  box-shadow: 0 0 0 0 rgb(245 207 215) !important;
}
  .lngqr{
    font-size: 43px;
  }
  .leftqr{
    display: none;
  }
  .qrshopname{
    font-size: 3vh;
  }
  .shopdetailsqr{
    position: absolute;
    bottom: 10px;
    line-height :0px;
  }
  .shopdetailsqr p {
    color: white;
    margin-bottom: 2px !important;
}
.btnqr{
  position:absolute  !important;
  bottom: 5px  !important;
  left: 5px  !important;
  border-radius: 5px  !important;
  background-color: transparent !important;
  font-size: 10px !important;
  padding: 2px !important;
  color: white !important;
  border-color: white !important;
  

}
  .logo-shopqr{
    top:10%;
    position:absolute;
    width: 40% !important;
    z-index: 2;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  
     height: 40% !important;
    position: absolute;
    border-radius: 8px !important;
    bottom: 23%;
    left: 3%;
    object-fit: contain;
    background: white;
    padding: 2px;
  
  }
  .qrscan .card{

    overflow: hidden;
    }
    .qrscan .banner-img{
     
      position:initial;
       width: 100%;
        height: auto;
        height: 100%;
        min-height: 110px;
        object-fit: cover;
       
    }
 
  .register{
    background-color: #00000059;
    border-radius: 10px;
 
    
  
  }
  .register a{
    border-color: #f3f6f9 !important;
  }
  .register h2{
    font-size: 15px !important;
  }
  .register p{
    font-size: 10px !important;
  }
  .dishesList{
    top: 60px;
    background-color: #fff;
    padding: 10px;
    z-index: 8;
   
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0 3px 5px rgb(57 63 72 / 10%);
  }
 .address{
   font-size: smaller;
 }
  .nav{
    white-space: nowrap;
    overflow-x: auto;
    display: block;
    flex-wrap: nowrap;
    max-height: 59px;
 
  }
  .nav li a{ 

      color: #333333;
      margin: 5px 0;
      padding: 6px 10px;
      border-radius: 29px;
      font-weight: 600;
      font-size: 16px;
      -moz-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
   
  }
 .h-dish .dishdesc{
  
 }
  .nav li{
    display: inline-block;
    float: none;
    margin: 0 !important;
    flex: auto;
    padding: 0;
  }
  .my-dish h6{
    min-height: 20px;
    font-size: 3.2vw !important;

  }
  .my-dish img{
    height: 120px;
    object-fit: cover;
  }
  .details-shop{
    bottom: 0;
   position: absolute;
   display: block;
   width: 100%;
   padding: 5% 5% 2% 5%;
  }
  .pt-5, .py-5 {
    padding-top: 4rem !important;
}
  .restaurant-detailed-header-right.text-right {
    text-align: center !important;
    padding-top: 20px;
}
  .restaurant-detailed-header {
    top: 22%;
    
}
  
  .restaurant-detailed-header-left {
    text-align: start;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 10px !important;
}
.logo-shop{
  top: 15%;
  position: absolute !important;
  width: 20% !important;
  z-index: 2;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  height: 20% !important;
  position: absolute;
  border-radius: 8px !important;
  bottom: 23%;
  right: 6%;
  object-fit: contain;
  background: white;
  padding: 2px;

}
  .restaurant-baner{
       min-height: 250px;
  }
   
  .banner-img{
    width: auto;
    position: absolute;
    height: 250px;
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d;
    object-fit: cover;
  }
.shop-content{
  padding-top: 27%;
}
.shop-content h3{
  font-weight: 800 !important;
  font-size: 7.5vw;;
  margin-bottom: 25px !important;
}
.shop-row{
  border-radius: 10px;
  border: 2px white solid;
  padding: 4px !important;
  margin: 10px !important;
  position: relative;
  width: fit-content;
  float: RIGHT;
     
}
.shop-row span{
  font-weight: 800;
  bottom: 12px;
  vertical-align: middle!important;}
.shop-content h4{
  font-weight: 500 !important;
  font-size: 3.5vw;;
 }
.shop-name{
  margin-right: 15px !important;
  font-size: 3.5vw  !important;
}
.shop-content h5{
  font-weight: 500 !important;
  font-size: 3.5vw;
  padding-top: 10px;
}

  .h-restaurant {
    padding: 10px !important;
    display: table;
}
.h-category h6 {
  text-align: center;
  font-size: 0.7rem;
}

 
.feture-logo {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
   
  position: absolute;
  border-radius: 8px !important;
  bottom: 32%  !important;
  right: 3%;
  object-fit: contain;
  background: white;
  padding: 2px;
}
  .navbar-brand img {
    max-width: 106px;
    padding-top: 0px;
  }
  .osahan-nav .nav-link {
    font-weight: 800;
    padding: 1px 0px !important;
    margin: 0 0 0 0px;
  }
  .osahan-nav .nav-link {
    margin: 0px;
    text-align: center;
    padding: 0px 0 !important;
    border-bottom: 0px solid #eef0ef;
  }
  .section-header h2 {
    font-size: 1rem;
  }
  .myhead {
    font-weight: 900 !important;
    font-size: 1.5rem !important;
  }
  .homepage-search-title h5 {
    font-weight: 600 !important;
    font-size: 0.8rem;
    margin-top: 20px;
}
}
ion-tab-bar {
  --background: #ffffff;
  border-top: 0.0625rem solid #e2e2e2 !important;
}

ion-item-divider {
  background-color: #f5365c;
  color: #fff;
  border-radius: 0px 10px 0px 0px;
}

.feture-logo {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  width: 5rem ;
  height: 30% ;
  position: absolute;
  border-radius: 8px !important;
  border: 2px solid white;
  top: 6%;
  right: 3%;
  object-fit: contain;
  background: #162a56;
  padding: 2px;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.myblock {
  border-radius: 7px;
  box-shadow:0 .125rem .25rem rgba(0, 0, 0, .075)!important ;
}
.myfrontslide .my-featured img.banner-img-front{
  height: 200px ;
}
.rounded-lg {
  border-radius: 0;
}
.mycategories {
  background: #32325d;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-radius: 10px;
}
.table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
  font-size: 0.8rem;
}
.myfrontslide {
   margin-bottom: 10px;
  border-radius: 20px;
}

.myrow {
  display: block !important;
}

.my-featured {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.my-featured img {
  width: 400px;
  max-width: 100%;
  height: auto;
  height: 200px;
  object-fit: cover;
}

.item-name {
  font-size: 16px;
  font-size: 3vw;
}

.fav-dish {
  width: 100%;
  height: 130px;
  object-fit: cover;
}

img.feture-logo {
  object-fit: scale-down;
}
.osahan-account-page-left .nav-link.active i {
  background: #f4355b !important;
}
.btn {
  position: relative;
  text-transform: uppercase;
  will-change: transform;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  border: #fff 2px solid;
  text-align: start;
  font-weight: 800;
}
.w100 {
  width: 100%;
}
.section-header p{
  font-size: 15px;
  line-height: normal;
}
.btn-outline-primary {
  color: #162b4c !important;
  border-color: #162b4c !important; 
  padding: 6px;
  font-weight: 800;
  text-align: center;
}
.btn-outline-secundary {
  color: #ef113d !important;
  border-color: #ef113d !important; 
  padding: 6px;
  font-weight: 800;
  text-align: center;
}
.mywhite {
  background-color: white !important;
  color: #ef113d;
  border-color: var(--color-selected);
}

.mycatslide {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  margin-bottom: 12px;
  border-radius: 10px;
}

.my-cat {
  z-index: 10000;
  font-size: 0.6em;
  font-weight: 900 !important;
  color: #fff;
  top: 0%;
  right: 0px;
  text-align: center;
  align-items: center;
  position: absolute;
  background: #525f7fb0;
  /* width: 102%; */
  height: 100%;
  border-radius: 0 5px 5px 0;
  /* min-height: 30px; */
  /* text-align: center; */
  floa: right;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  margin: 0px;
}

.topnavbtn {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 4px;
}
.topnavbtn .text-muted {
  font-size: 1em;
}

ion-item {
  --inner-border-width: 0px 0px 0px 0px;
  margin-bottom: 10px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}


.range-bar {
  background: #f5365c !important;
}

.ion-page i.info {
  color: #f7839a;
  font-size: 10px;
  font-weight: 700;
}

.range-bar {
  background: #f5365c !important;
}

ion-tab-button {
  --color: #32325d;
  --color-selected: #f5365c;
  --ripple-color: #f5365c;
}

.pack-btn {
  border-radius: 8px;
  background-color: #fff !important;
}

.pack-count {
  line-height: 30px;
  color: #f4355bc9;
  font-weight: 900;
}

.price-stile {
  font-weight: 700;
  font-size: 14px;
}

.pack-btn:before {
  content: "";
  display: block;
  height: 2px;
  width: 10px;
  position: relative;
  top: 48%;
  left: 30%;
}

.qrbtn {
  background-color: #ffff;
  border: 3px solid #fff;
  border-radius: 40px;
  width: 76px;
  height: 76px;
  --background: #f4355b;
}

.swiper-container {
  padding-right: 10px;
}

ion-tab-bar {
  height: 62px !important;
  padding: 4px !important;
}

.qricn {
  font-size: 36px;
  color: var(--color-selected);
}

.bg-white {
  background-color: #fff !important;
}

.featured-badge {
  position: absolute;
  top: 0;
  left: 0em;
}
.featured-badge :before {
  content: "Featured";
  position: absolute;
  top: 0;
  left: 0;
  padding-right: 10px;
  height: 0px;
  border: 12px solid #ff2c55;
  border-right-color: transparent;
  line-height: 0px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  color: #fff;
  font-size: 12px;
  z-index: 1;
}

.featured-badge :after {
  content: "";
  top: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  width: 100%;
  height: 22px;
  position: absolute;
  z-index: 1;
  -webkit-animation: slide 2.2s infinite;
  animation: slide 2.2s infinite;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#007db9e8', GradientType=1);
}


.swiper-wrapper {
  padding-left: 8px;
}

.myfrontslider {
  padding-right: 6px;
  margin-top: 8px;
}

.slideframe {
  position: absolute;
  bottom: 10px;
  color: #fff;
  background: #00000047;
}

.slide-name {
  color: #fff;
  font-size: 18px;
}

.slide-desc {
  font-size: 14px;
}

.fab-horizontal-center {
  margin-left: unset;
  margin-inline-start: -38px !important;
}

.btnsrch {
  height: 44px;
  top: -5px;
}

.favbadge {
  position: absolute;
  bottom: 60px;
  right: 5px;
  background: white;
  border: 1px #32325d solid;
}
.owl-theme .owl-nav {
  margin-top: 10px;
  position: absolute;
  bottom: 0px;
  right: 60px;
}

.owl-theme .owl-nav .owl-prev {
  left: -20px;
}
.owl-theme .owl-nav .owl-next {
  left: 20px;
}
.rounded-pill {
  border-radius: 2rem !important;
}
.section {
  position: relative;
   
  padding-bottom: 2rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #152b4c;
  border-color: #152b4c;
}
.react-bs-table-sizePerPage-dropdown{
  padding: 0 !important;
  border: 0px solid !important;
  margin-left: 0px !important;
  margin-bottom: 5px !important;

  
}
.filter-label{
  margin-left: 5px;
}
.filter-label input{
  border-radius: 8px;
 padding: 5px;
}
.btn-sm {
  font-size: 0.8rem;
}
.react-bootstrap-table-pagination div{
  margin-bottom: 5px;
}
.react-bs-table-sizePerPage-dropdown button{
  padding: 3px !important;
  border-radius: 5px !important;
  border: 2px solid !important;
  color: #152b4c !important;
  background-color: #fff !important;
  font-size:small !important;
}
.page-link {
  position: relative;
  display: block;
  padding: .2rem .575rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #152b4c;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.react-bootstrap-table tr:nth-child(2n+1){
  background-color: #edf1f4;
  color: #152b4d;
  font-weight: 700;
}
.react-bootstrap-table tr:nth-child(2n){
  background-color: #fff;
  color: #152b4d;
  font-weight: 700;
}
.react-bootstrap-table  .reset-expansion-style{
border:2px solid #152b4c;
color:#fff;
background-color:#fff;
}
.react-bootstrap-table  th{
  background-color: #fff;
}
.pack-btn, .pack-btn-plus{
  color: #152b4c;
  padding: 4px;
  font-weight: 100;
  border: 1px solid gray;
  border-radius: 10px;
}
.count-number .btn {
  padding: 6px 8px;
  font-size: 12px;
  border-radius: 0px;
  border-color: #172a4d;
}
.count-number .btn.left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.count-number-input {
  width: 24px;
  text-align: center;
  margin: 0 -4px;
  background: #172a4d;
  border: none;
  color: #fff;
  height: 34px;
  font-weight: 700;
  border-radius: 0px;
  vertical-align: bottom;
}
.count-number .btn.right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.pricetext{
  font-size: medium;
  font-weight: 700;
  margin-top: 10px;
}
.a_to_cart:hover,.r_from_cart:hover{
  background-color: #172a4d;
  color: #fff !important;
}
.cartsec{
 
  margin-bottom: 3rem !important;
  max-height: 46%;
  display: block;
  /* padding: 20px; */
  
}
.btncardbottom{
  width: 94%;
  margin-left: 3%;
  margin-bottom: 3%;
  font-weight: 900;
}
.noLogin h4{
  color: gray;
  font-size: 0.8rem;

}
.noLogin{
text-align: center;
font-size: 8px;
color: gray;
}
.pt-6, .py-6 {
  padding-top: 5rem !important;
}
.totalsum{
  font-size: 1.8rem;
    font-weight: 900 !important;
}
.info-modal .modal-content{
border-radius: 10px;
}
.b-2{
  border:2px solid !important;
}
.b-3{
  border:3px solid !important;
}
.b-4{
  border:4px solid !important;
}
.b-5{
  border:5px solid !important;
}
.b-6{
  border:6px solid !important;
}

.imagePageDetails .react-parallax-content{
  background: #00000036;
  border-radius: 5px;
  padding-bottom: 10px;
}
.imagePageDetails .react-parallax-content h6{
 color: #fff !important;
 font-weight:700 ;
}
.demo
{
	padding: 100px 0;
}
.heading-title
{
	margin-bottom: 100px;
	color: #fff;
}

.pricingTable{
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
}

.pricingTable .pricingTable-header{
    background: #152a38;
    padding: 15px;
}

.pricingTable .heading{
    display: block;
    padding: 15px 0;
}

.pricingTable .heading:after {
    content: "";
    width: 28%;
    border-top: 1px solid #7c888f;
    display: block;
    margin: 15px auto 0;
}

.pricingTable .heading h3{
    font-size: 24px;
    color: #fff;
    text-transform: uppercase;
    margin:0;
    letter-spacing: 2px;
}

.pricingTable .price-value{
    font-size: 45px;
    color:#fff;
    padding: 10px 0 30px 0;
    display: block;
}

.pricingTable .month{
    display: block;
    font-size: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-top: 15px;
    color: #7f909a;
}

.pricingTable .btn{
    display: block;
    font-size: 22px;
     
    border: none;
    border-radius: 0;
    padding: 20px 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.pricingTable .btn1:after{
    content: "\f00c";
    font-family: 'FontAwesome';
    margin-left: 5px;
    opacity: 0;
    transition:all 0.5s ease 0s;
}

.pricingTable:hover .btn:after{
    opacity: 1;
}
.pricingTable-sign-up{
  background-color: #152a38;
  color: #fff;
  }.pricingTable-sign-up .btn{
    
    color: #fff;
    }
  .pricingTable-sign-up:hover{
    background-color:#f4355b;
    }
@media screen and (max-width: 990px){
    .pricingTable{
        margin-bottom: 20px;
    }
}







/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

 

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/



/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}




/*//////////////////////////////////////////////////////////////////
[ Contact ]*/

.container-contact100 {
  width: 100%;  
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: transparent;
  position: relative;
  z-index: 1;
}

.contact100-map {
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.wrap-contact100 {
  width: 50%;
  background: #fff;
  border-radius: 10px;
  padding: 82px 180px 33px 180px;
  position: relative;
  display: none;
}

.show-wrap-contact100 {
  visibility: visible;
  opacity: 1;
}


/*==================================================================
[ Form ]*/

.contact100-form {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 68px;
}

.contact100-form-title {
  display: block;
  width: 100%;
  font-family: Poppins-Bold;
  font-size: 39px;
  color: #333333;
  line-height: 1.2;
  text-align: left;
  padding-bottom: 44px;
}



/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
  padding-bottom: 13px;
  margin-bottom: 65px;
}

.rs1-wrap-input100 {
  width: calc((100% - 30px) / 2);
}

.label-input100 {
  font-family: Poppins-Regular;
  font-size: 15px;
  color: #999999;
  line-height: 1.5;
  padding-left: 5px;
}

.input100 {
  display: block;
  width: 100%;
  background: transparent;
  font-family: Poppins-SemiBold;
  font-size: 18px;
  color: #555555;
  line-height: 1.2;
  padding: 0 5px;
}

.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #ff4b5a;
}


/*---------------------------------------------*/
input.input100 {
  height: 40px;
}


textarea.input100 {
  min-height: 110px;
  padding-top: 9px;
  padding-bottom: 13px;
}


.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}


/*------------------------------------------------------------------
[ Button ]*/
.container-contact100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin-top: -25px;
}

.contact100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 160px;
  height: 50px;
  background-color: #ff4b5a;
  border-radius: 25px;

  font-family: Poppins-Medium;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  box-shadow: 0 10px 30px 0px rgba(255, 75, 90, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(255, 75, 90, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(255, 75, 90, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(255, 75, 90, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(255, 75, 90, 0.5);
}

.contact100-form-btn i {
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.contact100-form-btn:hover {
  background-color: #333333;
  box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
}

.contact100-form-btn:hover i {
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -o-transform: translateX(10px);
  transform: translateX(10px);
}


/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 992px) {
  .wrap-contact100 {
    padding: 82px 80px 33px 80px;
  }
}

@media (max-width: 768px) {
  .rs1-wrap-input100 {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .wrap-contact100 {
    padding: 82px 15px 33px 15px;
  }
}


/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 58%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 2px;
  pointer-events: none;

  font-family: Poppins-Medium;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 16px;
  top: 58%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}


/*==================================================================
[ Contact more ]*/

.contact100-more {
  font-family: Poppins-Regular;
  font-size: 14px;
  color: #999999;
  line-height: 1.5;
}

.contact100-more-highlight {
  color: #ff4b5a;
}

/*==================================================================
[ Button hide form ]*/

.contact100-btn-hide {
  color: #333333;
  font-size: 14px;

  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #ececec;
  border-radius: 50%;
  top: -15px;
  right: -15px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.contact100-btn-hide:hover {
  background-color: #333333;
  color: #fff;
}


/*==================================================================
[ Button show form ]*/
#specmarker{
  font-size: 29px !important;
  width: 40px !important;
  height: 40px !important;
}
.floatingMapButton{
  position:absolute;
  z-index: 2;
  top:40%;
  left: 0px;
}
.floatingMap{
  background-color: white;
  width: 20%;
  height: 80%;
  top: 15%;
  border-radius: 20px;
  overflow-y: scroll;
}
.contact100-btn-show {
  color: #fff;
  font-size: 39px;

  position: fixed;
  z-index: -1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  cursor:'pointer';
  border-radius: 50%;
 
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.contact100-btn-show::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 100%;
  background-color: #ff4b5a;
  border-radius: 50%;

  top: 0;
  left: 0;
  
  box-shadow: 0 10px 30px 0px rgba(255, 75, 90, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(255, 75, 90, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(255, 75, 90, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(255, 75, 90, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(255, 75, 90, 0.5);

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  -webkit-animation: beatbtn 0.9s ease-in-out infinite;
  animation: beatbtn 0.9s ease-in-out infinite;
}


@keyframes beatbtn {
  0% {
    background-color: rgba(255, 75, 90, 1);
    transform-origin: center;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  50% {
    background-color: rgba(255, 75, 90, 0.8);
    transform-origin: center;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.ContactForm{
  background: #fff;
    padding: 10px;
    padding-top: 50px;
}

.scrollDetails{
  margin-top: -139px;
}


.banner-img-shop{
  object-fit: cover;
    width: 100%;
    height: 360px;
    border-radius: 7px 7px 0 0;

}

.baners-on-shop{
  border-radius: 7px;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
    background-color: white !important;
}